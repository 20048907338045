package dev.psiae.psiaefun.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.CSSColor
import com.varabyte.kobweb.compose.css.StyleVariable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.init.KobwebConfig
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.navigation.toOpenLinkStrategy
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.toAttrs
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.ColorPalettes
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.fr
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import dev.psiae.psiaefun.HeadlineTextStyle
import dev.psiae.psiaefun.SubheadlineTextStyle
import dev.psiae.psiaefun.components.layouts.PageLayout
import dev.psiae.psiaefun.toSitePalette

// Container that has a tagline and grid on desktop, and just the tagline on mobile
val HeroContainerStyle = CssStyle {
    base { Modifier.fillMaxWidth().gap(2.cssRem) }
    Breakpoint.MD { Modifier.margin { top(20.vh) } }
}

// A demo grid that appears on the homepage because it looks good
val HomeGridStyle = CssStyle.base {
    Modifier
        .gap(0.5.cssRem)
        .width(70.cssRem)
        .height(18.cssRem)
}

private val GridCellColorVar by StyleVariable<Color>()
val HomeGridCellStyle = CssStyle.base {
    Modifier
        .backgroundColor(GridCellColorVar.value())
        .boxShadow(blurRadius = 0.6.cssRem, color = GridCellColorVar.value())
        .borderRadius(1.cssRem)
}

@Composable
private fun GridCell(color: Color, row: Int, column: Int, width: Int? = null, height: Int? = null) {
    Div(
        HomeGridCellStyle.toModifier()
            .setVariable(GridCellColorVar, color)
            .gridItem(row, column, width, height)
            .toAttrs()
    )
}

@Page
@Composable
fun HomePage() {
    PageLayout("Home") {
        Column(HeroContainerStyle.toModifier(), horizontalAlignment = Alignment.CenterHorizontally) {
            Box {
                val sitePalette = ColorMode.current.toSitePalette()

                Div(HeadlineTextStyle.toAttrs()) {

                    SpanText(
                        "HI",
                        Modifier
                            .color(when (ColorMode.current) {
                                ColorMode.LIGHT -> Colors.Black
                                ColorMode.DARK -> Colors.White
                            })
                            // Use a shadow so this light-colored word is more visible in light mode
                            /*.textShadow(0.px, 0.px, blurRadius = 0.5.cssRem, color = Colors.Gray)*/,

                    )
                }
            }
            Box(Modifier.height(24.px))
            val ctx = rememberPageContext()
            Button(
                onClick = { evt ->
                    val openInternalLinksStrategy = evt.toOpenLinkStrategy(KobwebConfig.Instance.openLinkStrategies.internal)
                    val openExternalLinksStrategy = evt.toOpenLinkStrategy(KobwebConfig.Instance.openLinkStrategies.external)
                    ctx.router.navigateTo(
                        "https://patron.psiae.fun",
                        UpdateHistoryMode.PUSH,
                        openInternalLinksStrategy = openInternalLinksStrategy,
                        openExternalLinksStrategy = openExternalLinksStrategy,
                    )
                },
                colorPalette = ColorPalettes.Brown,
                modifier = Modifier.boxShadow(0.px, 0.px, blurRadius = 0.3.cssRem, color = Colors.Gray)
            ) {
                Text("SEE PATRONS")
            }
        }
    }
}
